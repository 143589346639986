import React from "react";

const checkURL = forceUrl => {
	let url = window.location.href; // get current url
	// console.log("url is ", url);

	if (url.split(".")[1] === "herokuapp"){
		if (forceUrl){return forceUrl} // if we force the url (for testing in localhost, for example) do that and ignore the rest.
		else{ 
			return "zenstant"
		}
	}

	url = url.replace(/^https?:\/\/?/i, "") // remove http/https
	if (url.split(".")[0] === "www" ){
		url = url.split(".")[1] // don't worry about .co vs .com etc. Matches for www. domain
	} else {
		url = url.split(".")[0] // don't worry about .co vs .com etc. Matches for naked domain
	}

	url = url.split("/")[0] // ignore anything after a slash (IE different pages, such as zenstant.co/hi)
		.split(":")[0]; // remove localhost port, if it's there
		// resulting URL should be just the name. IE: https://zenstant.co/hi#12345 -> zenstant

	// console.log("stripped url is ", url);

	if (url === "localhost"){
		if (forceUrl){return forceUrl} // if we force the url (for testing in localhost, for example) do that and ignore the rest.
		else{ 
			return "zenstant"
		}
	}; // catch for localhost

	if (url === "zenstant"){return "zenstant"};
	if (url === "endozen"){return "endo"};
	if (url === "zengraine"){return "migraine"};

	return url; // catchall. Note this should throw a 404 for non-controlled properties.
}

export default checkURL;